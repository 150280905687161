import "@fontsource/josefin-sans";
import "./index.css";

import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

import React, { useEffect, useState } from "react";
import { RouterProvider, RouterProviderProps } from "react-router-dom";
import ReactDOM from "react-dom/client";

import { AuthProvider, useAuthContext } from "AuthContext";
import { GqlProvider, useGqlContext } from "GqlContext";
import { DeviceContextProvider } from "DeviceContext";
import { UserType } from "type";

const Root: React.FC = () => {
  const { queryClient, graphQLClient } = useGqlContext();
  const { authData, validateAuthentication, staffMember } = useAuthContext();

  const [isValidating, setIsValidating] = useState<boolean>(true);
  const [isRouterReady, setIsRouterReady] = useState<boolean>(false);
  const [router, setRouter] = useState<
    RouterProviderProps["router"] | undefined
  >(undefined);

  useEffect(() => {
    setIsRouterReady(false);
    const initializeAuthentication = async () => {
      setIsValidating(true);
      await validateAuthentication();
      setIsValidating(false);
    };

    initializeAuthentication();
  }, []);

  useEffect(() => {
    const loadRouter = async () => {
      if (!isValidating && authData.userType) {
        switch (authData.userType) {
          case UserType.StaffMember:
            const { getStaffRouter } = await import("pages/staff/router");
            setRouter(
              getStaffRouter(queryClient, graphQLClient, staffMember!.role)
            );
            break;
          case UserType.Customer:
            const { GetCustomerRouter } = await import("pages/customer/router");
            setRouter(GetCustomerRouter());
            break;
          default:
            throw new Error("Unexpected authentication error.");
        }
        setIsRouterReady(true);
      } else if (!isValidating) {
        const { getLoginRouter } = await import("pages/auth/router");
        setRouter(getLoginRouter());
        setIsRouterReady(true);
      }
    };

    loadRouter();
  }, [authData, isValidating]);

  // TODO Setup for that when cookies/local storage is emptyied call logout.
  // useEffect(() => {
  //   const handleStorageChange = (event: StorageEvent) => {
  //     console.log(event);
  //   };

  //   window.addEventListener("storage", handleStorageChange);

  //   return () => {
  //     window.removeEventListener("storage", handleStorageChange);
  //   };
  // }, []);

  return (
    <div className="h-dvh w-dvw bg-bgray-600">
      {isRouterReady ? <RouterProvider router={router!} /> : null}
    </div>
  );
};

const App: React.FC = () => {
  return (
    <React.StrictMode>
      <GqlProvider>
        <AuthProvider>
          <DeviceContextProvider>
            <Root />
          </DeviceContextProvider>
        </AuthProvider>
      </GqlProvider>
    </React.StrictMode>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(<App />);

serviceWorkerRegistration.register();



